import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { ENDPOINTS, getRequestBody } from '@configs/api.config';
import { ConnectDeclineData } from '@libs/types';
import client from '@utils/client';
import { convertObjectKeysToCamelCase } from '@utils/formatter';

export const useSendOtpMutation = (options?: UseMutationOptions) => {
  const fetcher = (obj: { mobile: string; medium: 'SMS' | 'IVR'; isdCode?: string }) => {
    const { mobile, medium,  } = obj;
    const data = getRequestBody({
      type: 'SEND_OTP',
      payload: {
        super_admin_mobile: mobile,
        medium,
        isdCode: '+91',
      },
    });

    return client.post(ENDPOINTS.SEND_OTP, {
      json: data,
    });
  };
  // @ts-ignore : I don't know why this is not working 🙂
  return useMutation(fetcher, options);
};

export const useUserOtpVerifyMutation = (options?: UseMutationOptions) => {
  const verifyOtp = ({ userMobileNumber, otp, mobile, isdCode }) => {
    const data = getRequestBody({
      type: 'VERIFY_OTP',
      payload: {
        mobile: userMobileNumber,
        otp,
        isdCode: isdCode.replaceAll('+', ''),
        medium: 'SMS',
        super_admin_mobile: mobile,
      },
    });

    return client
      .post(ENDPOINTS.VERIFY_OTP, {
        json: data,
      })
      .json();
  };
  /// @ts-ignore : I don't know why this is not working 🙂
  return useMutation(verifyOtp, options);
};

// TODO : put this in query
export const useUserDataMutation = (options?: UseMutationOptions) => {
  const getUserDetails = () => client.get(ENDPOINTS.ME).json();
  return useMutation(getUserDetails, options);
};
interface Data {
  [key: string]: any;
}

export const useMutateUserData = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  const updateUserData = (value: Data) => {
    const data = getRequestBody({
      type: 'ME',
      payload: {
        ...value,
      },
    });

    return client
      .patch(ENDPOINTS.ME, {
        json: data,
      })
      .json();
  };

  // @ts-ignore : I can't fix this!
  return useMutation(updateUserData, {
    onSettled: (data, error) => {
      if (!error) {
        queryClient.setQueryData(ENDPOINTS.ME, convertObjectKeysToCamelCase(data));
      }
    },
    ...options,
  });
};

export const useUploadImageMutation = (options?) => {
  // const queryClient = useQueryClient();

  // @ts-ignore : :
  const uploadImage = ({ formData }) => {
    return client.post(ENDPOINTS.UPLOAD_IMAGE, { body: formData }).json();
  };

  //@ts-ignore : I don't know why this is not working 🙂
  return useMutation(uploadImage, {
    ...options,
  });
};

export const useDeleteImageMutation = (options?) => {
  const deleteImage = (index: number) => {
    const ENDPOINT = `${ENDPOINTS.DELETE_IMAGE}${index}/`;
    return client.delete(ENDPOINT).json(); // TODO add json to the response as a hook by defaults
  };

  return useMutation(deleteImage, options);
};

export const useHandleConnectDecline = (options?: UseMutationOptions) => {
  const fetcher = ({
    type,
    userId,
    recommendationId,
    message,
    is_mobile_verified,
  }: ConnectDeclineData) => {
    if (type === 'decline') {
      return client
        .patch(`me/recommendations/${recommendationId}/decline/`, {
          json: { is_mobile_verified: is_mobile_verified },
        })
        .json();
    } else {
      let data: {
        receiver: string;
        sparkle_message?: string;
        intro_message?: string;
        is_mobile_verified?: string;
      } = {
        receiver: userId,
        sparkle_message: message,
        is_mobile_verified,
      };
      if (type === 'intro_message') {
        data = {
          receiver: userId,
          intro_message: message,
        };
      }

      return client
        .post('me/connections/', {
          json: data,
        })
        .json();
    }
  };
  // @ts-ignore : I don't know why this is not working 🙂
  return useMutation(fetcher, options);
};

export const useSendSparkles = (options?: UseMutationOptions) => {
  const fetcher = ({ recommendationId, message }: ConnectDeclineData) => {
    const data = {
      sparkle_message: message,
      // receiver: userId,
    };

    const queryString = `me/connections/${recommendationId}/`;
    return client
      .put(queryString, {
        json: data,
      })
      .json();
  };
  // @ts-ignore : I don't know why this is not working 🙂
  return useMutation(fetcher, options);
};
// THIS IS DEPRECATED : WILL REMOVE THIS IN THE NEXT VERSION
export const useUpdatePartnerPreference = (options?: UseMutationOptions) => {
  const mutateFn = (value) =>
    client
      .post(ENDPOINTS.PARTNER_PREFERENCE, {
        json: value,
      })
      .json();

  return useMutation(mutateFn, options);
};

export const useMutateUserLocation = (options?: UseMutationOptions) => {
  const mutateFn = (value) =>
    client
      .put(ENDPOINTS.LOCATION_HOMETOWN, {
        json: value,
      })
      .json();

  return useMutation(mutateFn, options);
};

export const useUpdatePartnerPreferenceNew = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const mutateFn = (value) =>
    client
      .patch(ENDPOINTS.PARTNER_PREFERENCE_NEW, {
        json: value,
      })
      .json();

  return useMutation(mutateFn, {
    ...options,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.setQueryData(
          ENDPOINTS.PARTNER_PREFERENCE_NEW,
          convertObjectKeysToCamelCase(data)
        );
      }
    },
  });
};

export const useDeactivateAccount = (options?: UseMutationOptions) => {
  const mutateFn = (value) =>
    client
      .post(ENDPOINTS.DEACTIVATE_ACCOUNT, {
        json: value,
      })
      .json();

  return useMutation(mutateFn, options);
};

// Rearrange Images
export const useRearrangeImages = (options?: UseMutationOptions) => {
  const mutateFn = (value) =>
    client
      .patch(ENDPOINTS.REARRANGE_IMAGES, {
        json: value,
      })
      .json();

  return useMutation(mutateFn, options);
};

// Submit Feedback
export const useSubmitFeedback = (options?: UseMutationOptions) => {
  const mutateFn = (formData) => client.post(ENDPOINTS.SUBMIT_FEEDBACK, { body: formData }).json();
  return useMutation(mutateFn, options);
};

export const useDeleteUserMutation = (options?) => {
  const deleteUser = (payload) => {
    return client.delete(ENDPOINTS.ME, { json: payload }).json();
  };

  return useMutation(deleteUser, options);
};

//Received Request Accept and Decline
export const useReceivedRequestConnectDecline = (options?: UseMutationOptions) => {
  const connectDecline = ({ action, id }: any) => {
    return client
      .patch(`me/connections/${id}/`, {
        json: { action: action },
      })
      .json();
  };
  return useMutation(connectDecline, { ...options });
};

//Update the number of received cards that has been seen
export const useUpdateConnectionStatus = (options?: UseMutationOptions) => {
  const updateConnectionStatus = ({ payload, id }: any) => {
    return client.patch(`me/connections/${id}/`, { json: { ...payload } }).json();
  };
  return useMutation(updateConnectionStatus, { ...options });
};

//Set show_expiry_modal value to false in rrExpiry bottomsheet
export const useUpdateReceivedRequestExpiryBottomsheetStatus = (options?: UseMutationOptions) => {
  const updateModalStatus = ({ payload }: any) => {
    return client.patch(ENDPOINTS.GET_EXPIRY_STATUS, { json: { ...payload } }).json();
  };
  return useMutation(updateModalStatus, { ...options });
};
