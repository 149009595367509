import cn from 'classnames';

interface ILinkButtonProps {
  // title of the button
  title: string;
  //   when button is disabled
  isDisabled?: boolean;
  onClickButton: () => void;
  className?: string;
  testID?: string;
}

//@description: Link Button
export default function LinkButton({
  title,
  isDisabled,
  className,
  onClickButton,
}: ILinkButtonProps) {
  const Link_button =
    'text-pink-500 background-transparent font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ' +
    className;
  return (
    <button
      className={cn(Link_button, { 'text-[gray]': isDisabled })}
      type="button"
      disabled={isDisabled}
      onClick={onClickButton}
    >
      {title}
    </button>
  );
}
