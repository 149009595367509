import Secure from '@assets/svg/Secure.svg';

const SecureText = () => {
  return (
    <div className="flex justify-center w-full py-2 ">
      <Secure />
    </div>
  );
};

export default SecureText;
