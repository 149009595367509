import { useContext } from 'react';

import {
  AnalyticsManagerContext,
  AnalyticsManagerState,
} from '@analytics/AnalyticsManagerProvider';
import {
  EventPropertiesToPass,
  MixPanelUser,
  ONBOARDING_EVENT_NAME_TYPE,
  getEventProps,
} from '@analytics/events';

export interface UserProfile {
  Identity?: string;
  verificationStatus?: string;
  phone?: string;
  city?: string;
  country?: string;
  hometown?: string;
  onboarding_platform?: string;
  email?: string;
  gender?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  designation?: string;
  userAge?: number;
  name?: string;
}

type Service = 'mixpanel' | 'clevertap' | 'branch';

const useAnalyticsManager = () => {
  const { clevertap, mixpanel, branch } =
    useContext<AnalyticsManagerState>(AnalyticsManagerContext);

  const setUserProperty = ({ key, value }) => {
    const property = {};
    property[key] = value;
    mixpanel?.people?.set(property);
    clevertap?.profile?.push({ Site: { ...property } });
  };

  const setUserProfile = (userProfile: UserProfile) => {
    const {
      gender,
      Identity,
      phone,
      city,
      country,
      userAge,
      dateOfBirth,
      email,
      verificationStatus,
      hometown,
      designation,
    } = userProfile;
    clevertap?.profile?.push({ Site: { ...userProfile } });
    if (mixpanel.__loaded) {
      mixpanel?.identify(userProfile.Identity);
      if (gender) {
        mixpanel?.people?.set({
          gender: gender,
          $identity: Identity,
          onboarding_method: 'WEB',
          phone: phone,
          user_city: city,
          user_country: country,
          dob: dateOfBirth,
          Email: email,
          email,
          mobile: phone,
          verification_status: verificationStatus,
          hometown,
          designation,
          age: userAge,
        });
      }
    }
    branch?.setIdentity(userProfile.Identity);
  };

  const associateMixpanelUser = async (obj: MixPanelUser) => {
    mixpanel?.people?.set(obj);
  };

  const sendClevertapEvent = (eventName: ONBOARDING_EVENT_NAME_TYPE, payload = {}) => {
    const eventProperties = { ...getEventProps(eventName, payload), event_timestamp: Date.now() };
    clevertap?.event?.push(eventName, eventProperties);
  };

  const sendMixpanelEvent = (
    eventName: ONBOARDING_EVENT_NAME_TYPE,
    payload: EventPropertiesToPass
  ) => {
    if (mixpanel.__loaded) {
      mixpanel?.track(eventName, {
        ...getEventProps(eventName, payload),
        event_timestamp: Date?.now(),
      });
    }
  };

  const sendEvent = (
    eventName: ONBOARDING_EVENT_NAME_TYPE,
    payload: EventPropertiesToPass,
    service: Array<Service> | 'all'
  ) => {
    if (service === 'all' || service.includes('mixpanel')) {
      sendMixpanelEvent(eventName, payload);
    }
    if (service === 'all' || service.includes('clevertap')) {
      sendClevertapEvent(eventName, payload);
    }
    if (service === 'all' || service.includes('branch')) {
      branch?.logEvent(eventName);
      branch?.track(eventName, payload, (err) => {
        if (err) {
          console.warn(`Branch failed to resolve link: ${err}`);
        }
      });
    }
  };
  const logOutBranch = () => branch?.logout();

  return {
    setUserProfile,
    sendEvent,
    associateMixpanelUser,
    isMixpanelLoaded: mixpanel?.__loaded,
    logOutBranch,
    setUserProperty,
  };
};

export default useAnalyticsManager;
