import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { HTTPError } from 'ky';
import router from 'next/router';
import toast from 'react-hot-toast';
import { FaInfoCircle } from 'react-icons/fa';
import { BottomSheet } from 'react-spring-bottom-sheet';

import Loader from '@assets/svg/infinity-loader-pink.svg';
import SecureText from '@components/SecureText';
import WhatsAppContact from '@components/WhatsAppContact';
import { OtpInputWrapper } from '@components/index';
import { useFetchGeneralConfig } from '@configs/generalConfig';
import { USER_ONBOARDING_STATUS } from '@configs/onBoarding.config';
import {
  useSendOtpMutation,
  useUserDataMutation,
  useUserOtpVerifyMutation,
} from '@hooks/mutations';
import { useGetUser } from '@hooks/queries';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import { User } from '@libs/types';
import { formatNumber } from '@utils/formatter';
import { GetNestedObjectKeyValue } from '@utils/general';
import { useGlobalStore } from '@zustand/store';

import OtpLimitExceeded from './OtpLimitExceeded';

interface Props {
  setOnboardingStep: Dispatch<SetStateAction<number>>;
  mobileNumber: string;
  userMobileNumber: string;
  isdCode?: string;
  attempt: number;
  setAttempt: (value) => void;
}

/**
 * @description checks if the user is an existing user or a new user, and handles the OTP verification
 */
const StepOtpVerify: FC<Props> = ({
  setOnboardingStep,
  mobileNumber,
  userMobileNumber,
  isdCode,
  attempt,
  setAttempt,
}) => {
  const signIn = useGlobalStore((state) => state.signIn);
  const [timeLeft, setTimeLeft] = useState(30);
  const [otp, setOtp] = useState('');
  const [otpLimitExceed, setOtpLimitExceed] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  // TODO : should be coming from backend
  const [correctOtp, setCorrectOtp] = useState<boolean>(true);

  const analyticsManager = useAnalyticsManager();

  useEffect(() => {
    analyticsManager.sendEvent('enter_otp_screen_web', { cleverTap: 'yes' }, [
      'clevertap',
      'mixpanel',
    ]);
  }, []);

  useEffect(() => {
    router.prefetch('/ai/matches');
  }, []);

  const setUserProfileInAnalyticsManager = (userData: User) => {
    const {
      verificationStatus = '',
      locations: { features } = { features: [] },
      mobile = '',
      isdCode: userIsdCode = '91',
      id = '',
    } = userData;

    let city: string, country: string;
    for (const feature of features) {
      if (feature.properties?.tag === 'residential') {
        city = feature.properties?.city;
        country = feature.properties?.country;
        break;
      }
    }

    const userProfile = {
      Identity: id,
      verificationStatus,
      Phone: `+${userIsdCode}${mobile}`,
      City: city,
      Country: country,
      onboarding_platform: 'web',
    };
    analyticsManager.setUserProfile(userProfile);
  };

  const generalConfig = useFetchGeneralConfig();

  const { refetch } = useGetUser({
    enabled: false,
  });
  // react-query
  const userDataMutation = useUserDataMutation({
    onSuccess: (data: User) => {
      setUserProfileInAnalyticsManager(data);
      // check if the user is an existing user or a new user
      if (data.status !== USER_ONBOARDING_STATUS.ON_BOARDING_STARTED) router.push('/ai/matches');
      else setOnboardingStep((step) => step + 1);
      setIsVerifying(false);
      refetch();
    },
    onError: async (err: HTTPError)  => {
      const errorData = await err?.response?.json();

      if (errorData?.code === 'AUTHUNAE01') {
        toast(errorData.message, {
          duration: 2000,
          style: { backgroundColor: 'white', color: 'black', marginBottom: '20px' },
        });
      }
      else{
      setCorrectOtp(false)}
    },
  });

  // will be called if the user tries to resend the otp
  const sendOtpMutation = useSendOtpMutation({
    onSuccess: async (data: any) => {
      const otpData = await data?.json();
      setTimeLeft(30);
      setAttempt(otpData?.current_session_attempts);
      setCorrectOtp(true);
    },
  });

  const userOtpVerifyMutation = useUserOtpVerifyMutation({
    onMutate: () => setIsVerifying(true),
    // eslint-disable-next-line
    onSuccess: (data: any) => {
      signIn({
        access: data.token.access_token,
        refresh: data.token.refresh_token,
      });

      userDataMutation.mutate();
    },
    onError: async (err: HTTPError) => {
      const errorData = await err?.response?.json();

      if (errorData?.code === 'AUTHUNAE01') {
        toast(errorData.message, {
          duration: 2000,
          style: { backgroundColor: 'white', color: 'black', marginBottom: '20px' },
        });
        setIsVerifying(false);
      }
      else{
        setCorrectOtp(false),   
        setIsVerifying(false);
        analyticsManager.sendEvent('incorrect_otp_entered_web', null, ['mixpanel']);
      }
      
      
    },
  });

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, isBottomSheetOpen]);

  const callbackOtpVerify = (value) => {
    userOtpVerifyMutation.mutate({ mobile: mobileNumber, otp: value, userMobileNumber, isdCode });
  };

  return (
    <>
      <form className="relative  h-[96%] pt-24 pb-6 z-10 flex flex-col ">
        <main className="flex flex-col flex-1 space-y-5">
          <h4>Enter your code</h4>
          <p className="text-h5">
            We sent an sms with a 4-digit code to{' '}
            <span className="font-semibold">{mobileNumber}</span>
          </p>

          <div className="flex items-center space-x-4">
            <OtpInputWrapper otp={otp} setOtp={setOtp} callbackOtpVerify={callbackOtpVerify} />
            {!correctOtp && (
              <div className="flex items-center space-x-2 text-warningText">
                <FaInfoCircle size={22} />
                <span>Incorrect OTP</span>
              </div>
            )}
          </div>

          <BottomSheet open={isBottomSheetOpen} onDismiss={() => setIsBottomSheetOpen(false)}>
            <div className="flex flex-col p-5 pt-6 pb-10 space-y-4">
              <h5 className="font-bold">
                Resend to <span>{mobileNumber}</span>
              </h5>
              <p>How would you like to receive your code?</p>
              <div className="flex items-center justify-center space-x-4">
                <BottomSheetResendButton
                  handleClick={() => {
                    if (attempt >= 5) {
                      setOtpLimitExceed(true);
                    } else {
                      sendOtpMutation.mutate({ mobile: mobileNumber, medium: 'SMS', isdCode });
                    }
                    setIsBottomSheetOpen(false);
                    setCorrectOtp(true);
                    setOtp('');
                  }}
                  text="Resend SMS"
                />
                <BottomSheetResendButton
                  handleClick={() => {
                    sendOtpMutation.mutate({ mobile: mobileNumber, medium: 'IVR', isdCode });
                    setIsBottomSheetOpen(false);
                    setCorrectOtp(true);
                    setOtp('');

                    analyticsManager.sendEvent('get_otp_on_call_web', null, ['mixpanel']);
                  }}
                  text="Get OTP on call"
                />
              </div>
            </div>
          </BottomSheet>

          {/* //bottom sheet */}

          <div className="flex items-center space-x-2">
            {timeLeft ? (
              <span className="text-grayIcon min-w-[110px]  ">
                Resend in{' '}
                <span className="font-bold text-[gray]"> 00:{formatNumber(timeLeft)} </span>
              </span>
            ) : (
              <button
                className="bg-white button text-primaryPink "
                onClick={(event) => {
                  event.preventDefault();
                  analyticsManager.sendEvent('resend_sms_web', null, ['mixpanel']);
                  setIsBottomSheetOpen(true);
                }}
              >
                Resend OTP
              </button>
            )}
            {attempt > 1 && <span className="text-grayIcon"> {attempt} / 5 attempts</span>}
          </div>
          {isVerifying && (
            <div className="py-10 text-center text-primaryPink">
              <Loader />
            </div>
          )}
        </main>
        {attempt < 5 && <SecureText />}
        {attempt === 5 && (
          <div className="absolute flex flex-col items-center justify-center w-full bottom-8">
            <p className="text-center font-light leading-[20px] text-[18px]">
              Still waiting for the OTP?
            </p>
            <WhatsAppContact
              message={GetNestedObjectKeyValue(generalConfig, 'otp_expire', 'use_case')}
              number={GetNestedObjectKeyValue(generalConfig, 'customer_support', 'ai_match_making')}
            >
              <p className="text-primaryPink text-center font-[400] mt-[18px] text-[16px]">
                Contact us
              </p>
            </WhatsAppContact>
          </div>
        )}
      </form>
      {otpLimitExceed && (
        <OtpLimitExceeded
          onClose={() => {
            setOnboardingStep(1);
            setOtpLimitExceed(false);
          }}
          contactUsDetails={{
            message: GetNestedObjectKeyValue(generalConfig, 'otp_expire', 'use_case'),
            number: GetNestedObjectKeyValue(generalConfig, 'customer_support', 'ai_match_making'),
          }}
        />
      )}
    </>
  );
};
export default StepOtpVerify;

interface BottomSheetResendButtonProps {
  handleClick: () => void;
  text: string;
}

const BottomSheetResendButton: FC<BottomSheetResendButtonProps> = ({ handleClick, text }) => {
  return (
    <button
      onClick={handleClick}
      // TODO : create tailwind component class for this
      className="px-4 py-2 transition-all border outline-none text-primaryPink border-primaryPink rounded-3xl hover:bg-primaryPink hover:text-white"
    >
      {text}
    </button>
  );
};
