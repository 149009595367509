import { Item } from '@libs/types';

import Accordion, { AccordionProps } from './Accordion';

export type AccordionWithListProps = Omit<AccordionProps, 'children'> & {
  list: Array<Item>;
  onItemSelect: (item: Item) => void;
  showSeeMore?: boolean;
  onSeeMorePress?: () => void;
  selectedItem: string | undefined;
  displayValue?: string;
  Icon?: ({ color }: { color?: string }) => JSX.Element;
};

const AccordionWithList = ({
  list,
  showSeeMore,
  onItemSelect,
  onSeeMorePress,
  toggleAccordion,
  accordionKey,
  value,
  displayValue,
  Icon,
  ...props
}: AccordionWithListProps) => {
  return (
    <Accordion {...{ ...props, toggleAccordion, accordionKey }} value={displayValue || value}>
      <div className="flex flex-col items-start py-1 space-y-1 ">
        {list.map((item, index) => (
          <button
            key={item.id || index}
            onClick={() => {
              toggleAccordion(accordionKey);
              onItemSelect(item);
            }}
            className="flex space-x-1 items-center"
          >
            {Icon && <Icon color="#333" />}
            <span>{item.displayText}</span>
          </button>
        ))}
        {/* // TODO : create separate component for see-more */}
        {showSeeMore && (
          <button className="text-base text-center text-primaryPink " onClick={onSeeMorePress}>
            See more
          </button>
        )}
      </div>
    </Accordion>
  );
};

export default AccordionWithList;
