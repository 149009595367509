import { FC } from 'react';

import ErrorIcon from '@assets/error.svg';

const Error: FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex items-center mt-4 space-x-2 text-base leading-[18px] text-warningText">
      <ErrorIcon className="flex-shrink-0" />
      <span>{message}</span>
    </div>
  );
};

export default Error;
