import { memo, useState } from 'react';

import AccordionWithChips, {
  AccordionWithChipsProps,
} from '@components/Accordion/AccordionWIthChips';
import { MARITAL_STATUS_LIST } from '@configs/onBoarding.config';
import { Item } from '@libs/types';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const MaritalStatus = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(savedValue);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithChipsProps = {
    accordionKey: accordionKey,
    list: MARITAL_STATUS_LIST,
    title: 'Marital Status',
    placeholder: 'e.g. Single',

    // Dynamic Props
    isOpen: isAccordionOpen,
    value: convertToStartCase(value),
    selectedItem: value,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithChips {...props} />;
};

export default memo(MaritalStatus);
