import { memo, useState } from 'react';

import AccordionWithChips, {
  AccordionWithChipsProps,
} from '@components/Accordion/AccordionWIthChips';
import { DRINK_PREFERENCE_LIST } from '@configs/onBoarding.config';
import { Item } from '@libs/types';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey?: string;
  isAccordionOpen?: boolean;
  toggleAccordionByKey?: (key: string) => void;
  savedValue?: string;
  updateGlobalStore?: (key, value) => void;
}

// Drinks Accordion components
const Drinks = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [value, setValue] = useState(savedValue); // state to manage the value change

  // On Item change in the accordion
  const onItemSelect = (item: Item) => {
    setValue(item.value);
    updateGlobalStore(accordionKey, item.value); // update the parent state
  };

  // Accordion props
  const props: AccordionWithChipsProps = {
    list: DRINK_PREFERENCE_LIST,
    title: 'Drinks',
    placeholder: 'Eg: Never',
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  return <AccordionWithChips {...props} />;
};

export default memo(Drinks);
