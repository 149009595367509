import { memo, useState } from 'react';

import { searchDesignationNames } from 'api/search';
import { BottomSheet } from 'react-spring-bottom-sheet';

import AccordionWithList, { AccordionWithListProps } from '@components/Accordion/AccordionWithList';
import SearchList, { SearchListProps } from '@components/SearchList';
import CollegeIcon from '@components/icons/CollegeIcon';
import { JOBS_LIST } from '@configs/onboarding.personalDetails.config';
import { Item } from '@libs/types';
import { convertToStartCase } from '@utils/formatter';

interface Props {
  accordionKey: string;
  isAccordionOpen: boolean;
  toggleAccordionByKey: (key: string) => void;
  savedValue: string;
  updateGlobalStore: (key, value) => void;
}

const Designation = ({
  accordionKey,
  isAccordionOpen,
  toggleAccordionByKey,
  savedValue,
  updateGlobalStore,
}: Props) => {
  const [isBottomSheetOpen, toggleBottomSheet] = useState(false);
  const [value, setValue] = useState(savedValue);

  const onItemSelect = (item: Item) => {
    setValue(item.value);
    toggleBottomSheet(false);

    updateGlobalStore(accordionKey, item.value);
  };

  const props: AccordionWithListProps = {
    list: JOBS_LIST,
    title: 'Job Title',
    placeholder: 'Eg: Product Designer',
    showSeeMore: true,
    onSeeMorePress: () => toggleBottomSheet(true),
    selectedItem: value,
    value: convertToStartCase(value),
    isOpen: isAccordionOpen,
    accordionKey,
    onItemSelect,
    toggleAccordion: toggleAccordionByKey,
  };

  const KEY_TO_SEARCH_LIST_PROPS: SearchListProps = {
    onSelect: onItemSelect,
    onSearchCallback: searchDesignationNames,
    placeholder: 'Search Job',
    initialData: JOBS_LIST,
    selectedData: value,
    Icon: CollegeIcon,
  };

  return (
    <>
      <AccordionWithList {...props} />
      <BottomSheet open={isBottomSheetOpen} onDismiss={() => toggleBottomSheet(false)}>
        <SearchList {...KEY_TO_SEARCH_LIST_PROPS} />
      </BottomSheet>
    </>
  );
};

export default memo(Designation);
