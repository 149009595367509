import classNames from 'classnames';

const SaveButton = ({ handleNext, isButtonEnabled }) => {
  return (
    <button
      className={classNames(
        'w-14 py-1 px-1 self-end bg-white hover:bg-primaryPinkLight cursor-pointer hover:bg-opacity-60 rounded-full hover:text-white  font-bold',
        {
          'text-primaryPink': isButtonEnabled,
          'text-disabled': !isButtonEnabled,
        }
      )}
      disabled={!isButtonEnabled}
      onClick={() => {
        if (!isButtonEnabled) return;

        handleNext();
      }}
    >
      Save
    </button>
  );
};

export default SaveButton;
