import { FC } from 'react';

import classNames from 'classnames';
import { MdClose } from 'react-icons/md';

interface Props {
  visible: boolean;
  onClickHandler: () => void;
}

const Clear: FC<Props> = ({ visible, onClickHandler }) => {
  return (
    <span
      className={classNames(
        'p-1 rounded-full cursor-pointer bg-grayShadow transition-opacity duration-500 ',
        {
          '!opacity-100': visible,
          'opacity-0': !visible,
        }
      )}
      onClick={onClickHandler}
    >
      <MdClose className=" text-h6 text-grayIcon" />
    </span>
  );
};

export default Clear;
