// This might not be the perfect place to keep this hook inside the Accordion component.
import { useCallback, useState } from 'react';

import { Locations } from '@libs/types';

type KeyToValueMap = Record<string, string | number | undefined | Locations | object>;

/**
 * @description returns the key of first falsy value in the keyToValueMap which should be the first opened accordion
 * @param keyToValueMap is an object that maps the accordion keys to the respective values
 * @param startIndex : from which index to start the check , default is to check from start
 */

const getNextFalsyKey = (keyToValueMap: KeyToValueMap, startIndex = 0): string => {
  const initialKey = Object.entries(keyToValueMap)
    .slice(startIndex)
    .find((keyValue) => {
      const [, value] = keyValue;
      return !value;
    })?.[0] as string;

  return initialKey;
};

/**
 *
 * @param accordionKeys required : Tuple of unique string which will be used keep track of the accordion functionality
 * @param keyToValueMap required : Object that maps the accordion keys to the respective accordion-input values
 * @param config optional : additional configuration to manipulate the default behavior
 * @returns an object that contains all the util function to control the accordion functionality
 */

const useAccordion = <T extends Readonly<Array<string>>>(
  accordionKeys: T,
  keyToValueMap: KeyToValueMap,
  config?: {
    openInitialAccordionDelay?: number;
    disableInitialAccordionOpen?: boolean;
  }
) => {
  // It's an array of accordion-keys that are in open state , multiple accordion can be in open state at the same time
  const [openedKeys, setOpenedKeys] = useState<Array<string>>([]);

  const closeAccordion = (keyToClose: T[number]) => {
    setOpenedKeys(openedKeys.filter((key) => key !== keyToClose));
  };

  // close the current one and opens the next accordion which contains falsy value
  // TODO : This function can be refactored
  const openNextAccordion =
    // useCallback(
    (currentKey: T[number], updatedKeyToValue?: KeyToValueMap) => {
      // close the current accordion
      const updatedKeys = openedKeys.filter((key) => key !== currentKey);

      setOpenedKeys(updatedKeys.filter(Boolean));

      const indexOfCurrentKey = accordionKeys.indexOf(currentKey);
      const nextKey = getNextFalsyKey(updatedKeyToValue || keyToValueMap, indexOfCurrentKey + 1);

      if (!nextKey) return;
      const isAlreadyOpen = openedKeys.includes(nextKey);

      if (isAlreadyOpen || keyToValueMap[nextKey]) {
        return;
      }

      // added timeout to keep the animation smooth , it first closes the current accordion and then opens the next one sequentially, if there is no timeout it will close the the current and open the next accordion in parallel which does not look good
      setTimeout(() => setOpenedKeys([...updatedKeys, nextKey].filter(Boolean)), 300);
    };
  //   [keyToValueMap, openedKeys, accordionKeys]
  // );

  // opens the first accordion
  const openFirstAccordion = (updatedKeyToValue?: KeyToValueMap, callback?: () => void) => {
    const timeOut = setTimeout(() => {
      if (config?.disableInitialAccordionOpen) {
        return;
      }

      setOpenedKeys((prevOpenedKeys) => {
        const initialOpenedAccordion = getNextFalsyKey(updatedKeyToValue || keyToValueMap);
        if (prevOpenedKeys.filter(Boolean).length >= 1) {
          return prevOpenedKeys;
        }
        return initialOpenedAccordion ? [initialOpenedAccordion] : [];
      });
      callback?.();
    }, config?.openInitialAccordionDelay || 0);

    return () => {
      clearTimeout(timeOut);
    };
  };

  // open a specific accordion by key
  const openAccordionByKey = (key: T[number]) => {
    const isAlreadyOpen = openedKeys.includes(key);
    if (isAlreadyOpen) {
      return;
    }
    setOpenedKeys([...openedKeys, key]);
  };

  // toggles the accordion's open state
  const toggleAccordionByKey = useCallback((keyToToggle: T[number]) => {
    setOpenedKeys((prevOpenedKeys) => {
      const isAlreadyOpen = prevOpenedKeys.includes(keyToToggle);
      if (!isAlreadyOpen) {
        return [...prevOpenedKeys, keyToToggle];
      }
      return prevOpenedKeys.filter((key) => key !== keyToToggle);
    });
  }, []);

  // checks if a accordion is open or not, returns boolean
  const isOpen = (key: T[number]) => openedKeys.includes(key);

  return {
    openedKeys,
    openAccordionByKey,
    openNextAccordion,
    openFirstAccordion,
    toggleAccordionByKey,
    closeAccordion,
    isOpen,
  };
};

export default useAccordion;
